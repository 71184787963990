<template>
  <div>
    <div>
      <audio id="myAudio" controls></audio>
    </div>
    <div>
      <span id="desc"></span>
    </div>
    <v-btn @click="play">Play</v-btn>
  </div>
</template>
<script>
export default {
  data () {
    return {
      playIndex: 0,
      list: [{
        title: 'NaTe The great 1',
        url: 'https://firebasestorage.googleapis.com/v0/b/classi-lab.appspot.com/o/audiofiles%2FFy7KtkEMvlghWyh4OPubTAznG8d2%2F1611626426105.MP3?alt=media&token=d35f8033-cc12-4f22-b127-602b528d3fe7'
      },
      {
        title: 'NaTe The great 2',
        url: 'https://firebasestorage.googleapis.com/v0/b/classi-lab.appspot.com/o/audiofiles%2FFy7KtkEMvlghWyh4OPubTAznG8d2%2F1611626435721.MP3?alt=media&token=302a33e5-ca8a-4a54-b981-24bb1020fcd8'
      },
      {
        title: 'NaTe The great 3',
        url: 'https://firebasestorage.googleapis.com/v0/b/classi-lab.appspot.com/o/audiofiles%2FFy7KtkEMvlghWyh4OPubTAznG8d2%2F1611626445363.MP3?alt=media&token=34365428-5023-4205-8d92-303534ebca81'
      }]
    }
  },
  methods: {
    display (idx) {
      const desc = document.getElementById('desc')
      desc.innerHTML = this.list[idx].title
    },
    play () {
      const myAudio = document.getElementById('myAudio')
      if (this.playIndex < this.list.length) {
        myAudio.src = this.list[this.playIndex].url
        this.display(this.playIndex)
        this.playIndex++
        myAudio.play()
      }

      myAudio.addEventListener('ended', () => {
        // console.log('onended')
        if (this.playIndex >= this.list.length) return
        myAudio.src = this.list[this.playIndex].url
        this.display(this.playIndex)
        this.playIndex++
        myAudio.play()
      })
    },
    endedPlay () {
      // const myAudio = document.getElementById('myAudio')
      // console.log('onended')
      // myAudio.src = this.list[this.playIndex].url
      // this.display(this.playIndex)
      // this.playIndex++
      // myAudio.play()
      // myAudio.onended = function () {
      //   console.log('onended')
      //   myAudio.src = this.list[this.playIndex].url
      //   this.display(this.playIndex)
      //   this.playIndex++
      //   myAudio.play()
      // }
    }
  }
}
</script>
